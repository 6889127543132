<template>
    <div class="footer" ref="footer">
        <div class="containers">
            <div class="row">
                <div class="col-lg-8 col-md-6 col-sm-12">
                    <div class="logo_img_footer">
                        <div>
                            <img src="@/assets/vet.png" alt="">
                        </div>
                        <p>{{$t('message.vireakButhamExpress')}}</p>
                    </div>
                    <div style="padding-top: 8px;">
                        <div class="address">
                            <p class="address_text">{{$t('message.hqAddress')}}:</p>
                            <p class="address_description1">#29 St. 47, Songkat Sras Jork, <br> Khan Donpenh, Phnom Penh, Cambodia</p>
                        </div>
                        <div class="address">
                            <p class="address_text1">{{$t('message.email')}}:</p>
                            <p class="address_description">support@vireakbuntham.com</p>
                        </div>
                        <div class="address">
                            <p class="address_text1">{{$t('message.tel')}}:</p>
                            <p class="address_description">(+855) 81 911 911</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="social">
                        <span class="follow-us">{{$t('message.followUs')}}</span><br>
                        <a href="https://www.facebook.com/VirakBunthamExpress/" target="blank">
                            <img src="@/assets/footer/facebook.svg" style="width: 32px;margin-right: 10px;margin-top: 5px;" alt="">
                        </a>
                        <a href="https://t.me/virakbunthamexpress" target="blank">
                            <img src="@/assets/amenity/telegram.svg" style="width: 32px;margin-right: 10px;margin-top: 5px;" alt="">
                        </a>
                        <a href="https://www.instagram.com/vireak_buntham/?igshid=MzRlODBiNWFlZA%3D%3D" target="blank">
                            <img src="@/assets/footer/instagram.svg" style="width: 32px;margin-right: 10px;" alt="">
                        </a>
                        <a href="https://www.tiktok.com/@virakbuntham?_t=8dthpgdhhsf&_r=1" target="blank">
                            <img src="@/assets/footer/tiktok.svg" style="width: 32px;margin-right: 10px;" alt="">
                        </a>
                        <a href="https://www.youtube.com/@virakbuntham668" target="blank">
                            <img src="@/assets/footer/youtube.svg" style="width: 32px;margin-right: 10px;" alt="">
                        </a>
                        <p></p>
                        <span class="follow-us">{{$t('message.weAccepted')}}</span><br>
                        <img style="margin-top: 5px;width: 250px;" class="set-wid" src="@/assets/payment.png" alt="">
                    </div>
                </div>
            </div>
            <hr style="color:#D1D0D0;">
            <div class="row" style="padding-top: 10px">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="copyright">Copyright © 2023 vireakbuntham.com. All rights reserved.</div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 tex-cen mar-2 terms">
                    <div class="copyright"><router-link  to="/term" style="border-bottom: none !important; padding-bottom: 10px; color: #6E6E73;">{{ $t('message.termCondition') }}</router-link> | <router-link  to="/policy" style="padding-bottom: 10px; border-bottom: none !important; color: #6E6E73;">{{ $t('message.privacyPolicy') }}</router-link></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {
       
    }
}
</script>

<style scoped>
    .footer {
        bottom: 0%;
        background: #FDF1EA;
        width: 100%;
    }
    
    .logo_img_footer {
        display: flex;
        height: 45px;
        line-height: 45px;
    }
    .logo_img_footer div {
        width: 10%;
        height: 100%;
    }
    .logo_img_footer div img {
        width: 48px;
    }
    .logo_img_footer p{
        padding-left: 10px;
        color: #1D1D1D;
        font-weight: 700;
        font-size: 20px;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .address {
        display: flex;
    }
    .address_text {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
    }
    .address_text1 {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
    }
    .address_description1 {
        padding-left: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
    }
    .address_description {
        padding-left: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
    }
    .tex-center {
        text-align: center;
        border: 1px solid yellowgreen;
    }
    .follow-us {
        font-weight: 600;
        font-size: 18px;
        color: #1D1D1D;
    }
    .social .fa-facebook {
        margin-left: 0 !important;
    }
    .social i{
        margin-left: 5px;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        background: #230080;
        font-size: 20px;
    }
    .copyright {
        font-weight: 300;
        font-size: 14px;
        color: #6E6E73;
        letter-spacing: 1.5px;
    }
    .tex-cen {
        text-align: end;
    }
    @media (width < 960px) {
        .logo_img_footer div {
            width: 11%;
            height: 100%;
        }
        .logo_img_footer img {
            width: 45px;
        }
        .social {
            padding-left: 0px;
        }
        .copyright {
            text-align: center;
        }
        .tex-cen {
            margin-top: -15px;
            text-align: center;
        }
    }
    

    @media (width < 535px) {
        .logo_img_footer div {
            width: 15%;
            height: 100%;
        }
        .address_text {
            width: 15%;
        }
    }
    @media (width < 535px) {
        .logo_img_footer div {
            width: 15%;
            height: 100%;
        }
        .address_text {
            font-size: 13px;
        }
        .address_description {
            font-size: 13px;
        }
        .logo_img_footer p {
            font-size: 18px;
        }
        .social {
            margin-top: -23px;
        }
    }
    @media(width < 500px) {
        .mar-2 {
            margin-top: -30px;
        }
    }
    @media(width < 493px) {
        .follow-us {
            font-size: 15px;
        }
        .set-wid {
            width: 273px;
        }
        .address_description1,.address_text1 {
            font-size: 13px;
        }
        .copyright {
            font-size: 14px;
            text-align: center;
            font-weight: 500;
            line-height: 24px;
        }
        .terms{
            padding-top: 20px;
        }
    }
</style>