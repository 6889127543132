<template>
    <div>
        <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div>
        <div v-else class="term">
            <HeaderComponent />
            <div class="containers">
                <div class="row mb-10">
                    <div class="col-lg-7 col-md-6 col-sm-12">
                        <div class="text_span">
                            <span class="font-khmer">{{ $t('message.TermCon') }}</span> 
                        </div>
                        <ul>
                            <li class="above_text font-khmer">{{ $t('message.tc1') }}</li>
                            <li class="text_font font-khmer">{{ $t('message.tc2') }}</li>
                            <li class="text_font font-khmer">{{ $t('message.tc3') }}</li>
                            <li class="text_font font-khmer">{{ $t('message.tc4') }}</li>
                            <li class="text_font font-khmer">{{ $t('message.tc5') }}</li>
                            <li class="text_font font-khmer">{{ $t('message.tc6') }}</li>
                            <li class="text_font font-khmer">{{ $t('message.tc7') }}</li>
                            <li class="text_font font-khmer">{{ $t('message.tc8') }}</li>
                            <li class="text_font font-khmer">{{ $t('message.tc9') }}</li>
                            <li class="text_font font-khmer">{{ $t('message.tc10') }}</li>

                        </ul>
                        
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-12">
                        <div class="noted_img">
                            <img src="@/assets/terms/terms-and-conditions.svg" alt="">
                        </div>
                    </div>
                </div>
                <!-- <div class="row mb-10">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="text_span">
                            <span>Terms &</span> <span style="color: #DE5D0A;"> Condition</span>
                        </div>
                        <ul>
                            <li class="above_text">Passengers are required to arrive at the boarding point 15 minutes before the scheduled departure time for check in.</li>
                            <li class="text_font">It is the passengers' responsibility to ensure early or on-time arrival at the boarding station. Missed schedules will not be eligible for refunds. The purchase tickets will be expired after the bus departure.</li>
                            <li class="text_font">Purchased tickets are non-refundable but can be used as a coupon for future trips. Coupon refunds can be processed up to 12 hours before departure schedule. The Coupon valid three months this reschedule can be made only time with the same route and vehicle type.</li>
                            <li class="text_font">For crossing border Vireak Buntham Express Travel is not responsible for VISA entry to the country, in case any immigration rejects at the border, passenger will not eligible for refund the ticket.</li>
                            <li class="text_font">Infants under the age of 3 are not required to have tickets. They must be accompanied by an adult and sit on their lap. Children aged 3 and above must purchase their own seats at the full price. But for sleeping bus infant require to have their bed.</li>
                            <li class="text_font">Passengers can choose their preferred seats and purchase real-time tickets through the VET Express Mobile APP or website at any time before schedule.</li>
                            <li class="text_font">Passengers are allowed a maximum of 25kg of checked baggage and one carry-on item. Additional charges will apply for overweight baggage, based on the local delivery fee.</li>
                            <li class="text_font">Items such as strong-smelling food (e.g., durian), pets, firearms, narcotics, smuggled or illegal products, and other dangerous or flammable/explosive items are strictly prohibited from being brought onboard.</li>
                            <li class="text_font">Passengers are responsible for the safety of their personal belongings and valuables. Vireak Buntham Express Travel will not be held liable for any loss or damage to personal items.</li>
                        </ul>
                        
                    </div>
                </div> -->
            </div>
            
            <IncFooter />
        </div>
    </div>
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import IncFooter from '@/components/footer/IncFooter.vue';

export default {
    components: {
    HeaderComponent,
    IncFooter
},
    data() {
        return {
            loading: true,
            reloadTerm: "",
        }
    },
    methods: {
       
    },
    created() {
        localStorage.setItem('reloadHome', 'false');
        localStorage.setItem('reloadCompanyProfile', 'false');
        localStorage.setItem('reloadRestore', 'false');
        localStorage.setItem('reloadRetal', 'false');
        localStorage.setItem('reloadGallers', 'false');
        localStorage.setItem('reloadBlog', 'false');
        localStorage.setItem('reloadPrivacy','false');
        
        this.reloadTerm = localStorage.getItem('reloadTerm');
        if(this.reloadTerm != 'true') {
            localStorage.setItem('reloadTerm', 'true');
        }
        if(localStorage.getItem('reloadTerm') == 'true') {
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    },
}
</script>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=Battambang:wght@300;400&display=swap'); */
    .term {
        padding-top: 150px;
        padding-bottom: 50px;
        /* height: 100vh; */
    }
    .text_span {
        padding-bottom: 10px;
    }
    .text_span span{
        font-weight: 600;
        font-size: 36px;
        color: #1E1E1F;
    }
    .font-khmer{
        /* font-family: 'Battambang'; */
    }
    .above_text {
        font-size: 14px;
        font-weight: 400;
        color: #424245;
    }
    .text_font {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        line-height: 1.8;
    }
    .noted_img {
        /* width: 100%;
        height: 100%; */
        background: gainsboro;
    }
    .noted_img img{
        width: 100%;
        height: 100%;
    }
    @media (width < 960px) {
        .term {
            padding-top: 95px;
        }
    }
</style>